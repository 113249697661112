<template>
  <div class="Myspinner" v-show="spinner">
    <div class="spinner-border text-warning" style="width: 8rem; height: 8rem" role="status"></div>
    <p class="text-light mb-5 h1">Please wait.....</p>
  </div>
  <div id="nav">
    <div class="affichage-menu container-fluid mt-2">
      <div class="my-content">
        <div>
          <div class="not-menu" v-if="!jourSemaine">
            <h1 class="mt-5">{{ sorry }}</h1>
            <img src="../src/assets/notMenu.png" alt="menu" class="w-75" loading="lazy" />
          </div>
          <table class="w-100" v-if="jourSemaine">
            <thead>
              <tr class="d-flex justify-content-between text-center">
                <th class="w-25"></th>
                <th v-for="item in jourSemaine" :key="item.id" class="w-25 semaine"
                  :class="jour_day_semaine === item.id ? 'jour_semaine' : ''">
                  <span>{{ item.libelle }}</span>
                </th>
              </tr>
            </thead>
            <tbody class="d-flex justify-content-between">
              <tr>
                <td v-for="item in type" :key="item.id" class="d-block type_of_repas">
                  <span>{{ item.libelle }}</span>
                </td>
              </tr>

              <tr class="lundi_repas repas">
                <td class="d-block" :class="{ color: jour_day_semaine === 1 }" @click="
                  showMyMenu(
                    this.jourSemaine[0].repas.type_repas[0].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[0].repas.type_repas[0].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 1 }" @click="
                  showMyMenu(
                    this.jourSemaine[0].repas.type_repas[1].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[0].repas.type_repas[1].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 1 }" @click="
                  showMyMenu(
                    this.jourSemaine[0].repas.type_repas[2].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[0].repas.type_repas[2].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 1 }" @click="
                  showMyMenu(
                    this.jourSemaine[0].repas.type_repas[3].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[0].repas.type_repas[3].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
              </tr>

              <tr>
                <td class="d-block" :class="{ color: jour_day_semaine === 2 }" @click="
                  showMyMenu(
                    this.jourSemaine[1].repas.type_repas[0].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[1].repas.type_repas[0].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 2 }" @click="
                  showMyMenu(
                    this.jourSemaine[1].repas.type_repas[1].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[1].repas.type_repas[1].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>

                  <!-- <tr v-for="[key, value] of Object.entries(this.listTypesPackages)" :key="key">
                <td v-if="key !== null">{{key}}</td>
                <td v-if="key !== null">{{value}} fois</td>
              </tr> -->
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 2 }" @click="
                  showMyMenu(
                    this.jourSemaine[1].repas.type_repas[2].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[1].repas.type_repas[2].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 2 }" @click="
                  showMyMenu(
                    this.jourSemaine[1].repas.type_repas[3].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[1].repas.type_repas[3].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="d-block" :class="{ color: jour_day_semaine === 3 }" @click="
                  showMyMenu(
                    this.jourSemaine[2].repas.type_repas[0].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[2].repas.type_repas[0].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 3 }" @click="
                  showMyMenu(
                    this.jourSemaine[2].repas.type_repas[1].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[2].repas.type_repas[1].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 3 }" @click="
                  showMyMenu(
                    this.jourSemaine[2].repas.type_repas[2].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[2].repas.type_repas[2].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 3 }" @click="
                  showMyMenu(
                    this.jourSemaine[2].repas.type_repas[3].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[2].repas.type_repas[3].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="d-block" :class="{ color: jour_day_semaine === 4 }" @click="
                  showMyMenu(
                    this.jourSemaine[3].repas.type_repas[0].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[3].repas.type_repas[0].pivot.libelle)" :key="index">
                    <strong class="overflow ellipsis">-{{ item }}</strong>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 4 }" @click="
                  showMyMenu(
                    this.jourSemaine[3].repas.type_repas[1].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[3].repas.type_repas[1].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 4 }" @click="
                  showMyMenu(
                    this.jourSemaine[3].repas.type_repas[2].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[3].repas.type_repas[2].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 4 }" @click="
                  showMyMenu(
                    this.jourSemaine[3].repas.type_repas[3].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[3].repas.type_repas[3].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="d-block" :class="{ color: jour_day_semaine === 5 }" @click="
                  showMyMenu(
                    this.jourSemaine[4].repas.type_repas[0].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[4].repas.type_repas[0].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 5 }" @click="
                  showMyMenu(
                    this.jourSemaine[4].repas.type_repas[1].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[4].repas.type_repas[1].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 5 }" @click="
                  showMyMenu(
                    this.jourSemaine[4].repas.type_repas[2].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[4].repas.type_repas[2].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 5 }" @click="
                  showMyMenu(
                    this.jourSemaine[4].repas.type_repas[3].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[4].repas.type_repas[3].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="d-block" :class="{ color: jour_day_semaine === 6 }" @click="
                  showMyMenu(
                    this.jourSemaine[5].repas.type_repas[0].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[5].repas.type_repas[0].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 6 }" @click="
                  showMyMenu(
                    this.jourSemaine[5].repas.type_repas[1].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[5].repas.type_repas[1].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 6 }" @click="
                  showMyMenu(
                    this.jourSemaine[5].repas.type_repas[2].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[5].repas.type_repas[2].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 6 }" @click="
                  showMyMenu(
                    this.jourSemaine[5].repas.type_repas[3].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[5].repas.type_repas[3].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="d-block" :class="{ color: jour_day_semaine === 0 }" @click="
                  showMyMenu(
                    this.jourSemaine[6].repas.type_repas[0].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[6].repas.type_repas[0].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 0 }" @click="
                  showMyMenu(
                    this.jourSemaine[6].repas.type_repas[1].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[6].repas.type_repas[1].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 0 }" @click="
                  showMyMenu(
                    this.jourSemaine[6].repas.type_repas[2].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[6].repas.type_repas[2].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
                <td class="d-block" :class="{ color: jour_day_semaine === 0 }" @click="
                  showMyMenu(
                    this.jourSemaine[6].repas.type_repas[3].pivot.libelle
                  )
                ">
                  <span class="d-flex justify-content-center" v-for="(
                                    item, index
                                  ) in this.decomposerRepas(this.jourSemaine[6].repas.type_repas[3].pivot.libelle)" :key="index">
                    <b class="overflow ellipsis">-{{ item }}</b>
                  </span>
                </td>
              </tr>
            </tbody>
          </table> 

          <div class="d-flex justify-content-center mt-5" v-if="jourSemaine">
            <button class="btn btn-info lecteur_code position-relative text-light animate__animated animate__zoomIn"
              v-show="codeBarre" @click="showInputOfCodeBarre">
              <img style="width:80px;" src="../src/assets/click-touch-icon-isolated-on-transparent-background-free-png.webp" alt="qrcode" class="img_qrcode position-absolute" />
              <span class="lc-rifd text-dark" >Touch To Start</span>
            </button>
          </div>
          <br />
          <br />
          <div class="cont-marquee" v-show="showMsgMenu">
            <marquee-text>
              <h1 class="h1 text-dark fw-bold">
                <img src="../src/assets/stop.png" class="image_stop" alt="stop" /><strong>Nb</strong> : Please click on
                the Meal for more
                visibility.
              </h1>
            </marquee-text>
          </div>

          <div v-show="showVideoQr" v-if="jourSemaine">
            <div class="position-absolute qrcode animate__animated animate__zoomIn" v-if="showVideoQr">
              <qrcode-stream @decode="onDecode" :camera="camera" />
              <button class="btn annuler_video bg-danger position-absolute" v-if="showVideoQr" @click="hideVideo">
                &#x2190; Cancel
              </button>
            </div>
          </div>
          <div class="content" v-if="show">
            <div class="d-flex justify-content-center mb-5 position-absolute affichage-type-menu w-100">
              <div>
                <div class="Myspinner_for_show_repas" v-show="spinnerForShowRepas">
                  <div class="spinner-border text-warning" style="width: 6rem; height: 6rem" role="status"></div>
                </div>
                <button
                  class="btn btn-primary bouton-type bouton-one mx-2 animate__animated animate__bounceIn animate__delay-500ms position-absolute"
                  id="pt" :value="
                    this.jourSemaine[0].repas.type_repas[0].pivot.type_repas_id
                  " :title="this.jourSemaine[0].repas.type_repas[0].cout" @click="clickBtn1">
                  <img src="../src/assets/san.png" alt="food" class="w-100 position-absolute food-meal-one" /><img
                    src="../src/assets/meal.png" alt="food" class="w-100 position-absolute food-meal-two" /><span
                    class="position-absolute name-pt">{{
                      this.jourSemaine[0].repas.type_repas[0].libelle
                    }}</span>
                </button>
                <button
                  class="btn btn-primary bouton-type bouton-two mx-2 animate__animated animate__bounceIn animate__delay-300ms position-absolute"
                  id="dj" :value="
                    this.jourSemaine[0].repas.type_repas[1].pivot.type_repas_id
                  " :title="this.jourSemaine[0].repas.type_repas[1].cout" @click="clickBtn2">
                  <img src="../src/assets/spa.png" alt="food" class="w-75 position-absolute food-spa" /><span
                    class="position-absolute name-dj">{{
                      this.jourSemaine[0].repas.type_repas[1].libelle
                    }}</span>
                </button>
                <button
                  class="btn btn-primary bouton-type bouton-three mx-2 animate__animated animate__bounceIn animate__delay-300ms position-absolute"
                  id="gouter" :value="
                    this.jourSemaine[0].repas.type_repas[2].pivot.type_repas_id
                  " :title="this.jourSemaine[0].repas.type_repas[2].cout" @click="clickBtn3">
                  <img src="../src/assets/gouter.png" alt="food" class="w-100 position-absolute food-spa" /><span
                    class="position-absolute name-gouter">{{
                      this.jourSemaine[0].repas.type_repas[2].libelle
                    }}</span>
                </button>
                <button
                  class="btn btn-primary bouton-type bouton-four mx-2 animate__animated animate__bounceIn animate__delay-300ms position-absolute"
                  id="dinner" :value="
                    this.jourSemaine[0].repas.type_repas[3].pivot.type_repas_id
                  " :title="this.jourSemaine[0].repas.type_repas[3].cout" @click="clickBtn4">
                  <img src="../src/assets/food.png" alt="food" class="w-75 position-absolute food-gouter" /><span
                    class="position-absolute name-gouter">{{
                      this.jourSemaine[0].repas.type_repas[3].libelle
                    }}</span>
                </button>
              </div>
            </div>
            <div class="float-end">
              <button
                class="btn btn-dark d-block mb-3 solde position-relative animate__animated animate__bounceIn animate__delay-200ms"
                @click="show_account">
                <img src="../src/assets/solde.png" alt="food" class="w-50 position-absolute money-solde" /><span
                  class="my_money_solde">SOLDE</span>
              </button>

              <button class="btn btn-danger sortir animate__animated animate__bounceIn animate__delay-200ms"
                @click="annulerCommande">
                &#x2190; Cancel
              </button>
            </div>
          </div>
          <div class="text-center animate__animated animate__zoomIn" v-show="showInputBarre">
            <form @submit.prevent="verifLecteurCode">
              <input type="password" style="border-radius:10px" class="input" id="input_lecteur" @keyup="verifLecteurCode()"
                v-model="inputLecteurCode" :class="disabledInputLecteurCode ? 'd-none' : ''" autocomplete="off" />
            </form>

            <button class="btn btn-info btn-retour d-block mx-auto mt-3" @click="retourCommande">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                v-show="hiddenSpinner"></span>
              <span v-show="showCancel"> &#x2190; Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pop position-absolute" v-show="close">
    <div class="my_form animate__animated animate__zoomIn mt-5" 
    :class="this.$store.state.isVisibleSup || this.$store.state.isVisibleTakeAway ? 'add-padding-20':'add-padding-5'">
      <button class="btn btn-danger align-self-end fermer_quantity" @click="fermer">
        &#x2190;Cancel
      </button>
     
      <p class="animate__animated animate__zoomIn">[QUANTITY]</p>
      
      <!-- <div class="grid animate__animated animate__zoomIn"
      v-if="this.$store.state.functionPersonne === 'Student'">
        <span v-for="n in elem" :key="n" @click="action(n)"
        :class="{ selectedQuantity: n === finalyQuantity }"
        >
          {{ n }}
        </span>
        <span @click="remove"
         style="background:black;color:white;">Clear</span>
      </div> -->
      <div>
        <div class="bg-primary quantite changequantity mb-1 animate__animated animate__zoomIn">
        {{ quantiteCaterer }}
      </div>
      <div class="grid animate__animated animate__zoomIn py-3">
      <span v-for="n in elemCaterer" :key="n" @click="actionCaterer(n)"
      >
        {{ n }}
      </span>
      <span @click="remove"
       style="background:black;color:white;">Clear</span>
    </div>
      </div>
      <fieldset class="away animate__animated animate__zoomIn" 
      v-if="this.$store.state.functionPersonne !== 'Student'">
        
        <legend>Caterer ?</legend>
       <div class="radios">
         <div>
           <input type="radio" id="no-caterer" name="caterer" v-model="caterer_value" v-bind:value="0">
           <label for="no-caterer" class="radiolabel">No</label>
         </div>

         <div>
           <input type="radio" id="yes-caterer" name="caterer" v-model="caterer_value" v-bind:value="1">
           <label for="yes-caterer" class="radiolabel">Yes</label>
         </div>
       </div> 
      
     </fieldset>
      <fieldset class="away animate__animated animate__zoomIn" 
      v-if="this.$store.state.isVisibleTakeAway">
       
         <legend>Take away ?</legend>
        <div class="radios"> 
          <div>
            <input type="radio" id="no" name="away" v-model="takeAway" v-bind:value="0">
            <label for="no" class="radiolabel">No</label>
          </div>

          <div>
            <input type="radio" id="yes" name="away" v-model="takeAway" v-bind:value="1">
            <label for="yes" class="radiolabel">Yes</label>
          </div>
        </div>
       
      </fieldset>

     

      <fieldset class="away animate__animated animate__zoomIn"
       v-if="this.$store.state.isVisibleSup">
        
         <legend>Supplement ?</legend>
        <div class="radios">
          <div>
            <input type="radio" id="no-supplement" name="supplement" v-model="supplement" v-bind:value="0">
            <label for="no-supplement" class="radiolabel">No</label>
          </div>

          <div>
            <input type="radio" id="yes-supplement" name="supplement" v-model="supplement" v-bind:value="1">
            <label for="yes-supplement" class="radiolabel">Yes</label>
          </div>
        </div>
       
      </fieldset>

      <button class="btn enter" :disabled="finalyQuantity === ''" @click="send">
        Enter
      </button>
    </div>
  </div>
  <div v-show="LogoEntreprise" id="imageContent">
    <img src="../src/assets/iugb.jpg" style="width: 100px; height: 75px" alt="iugb" />
  </div>

  <div class="affiche_ticket position-fixed" id="my_ticket" v-show="showTicket">
    <div v-if="ticket" style="text-align: center">
      <img class="img-fluid" style="width: 80%" :src="ticket" alt="ticket" />
    </div>
    <p class="position-absolute validite_du_ticket">
      The ticket is valid until : {{ this.validite_du_ticket }}
    </p>
    <p>Quantity: {{ this.finalyQuantity }}</p>
    <!-- <p>Ticket Code : {{ this.codeQrForCard }}</p> -->
  </div>
  <div class="account-solde position-absolute" v-if="account">
    <div class="bg-light w-50 account-tableau-solde h-50 position-relative animate__animated animate__bounceIn">
      <button class="btn btn-danger position-absolute close-account" @click="fermer_account">
        close
      </button>
      <h1 v-if="account" class="badge" style="background:#FF7A00;">
        Account balance <br />{{ my_account_solde }}<sub>XOF</sub>
      </h1>
    </div>
  </div>
  <div v-show="showWindowRepas" class="window_repas" @click="closeWindowMyMenu">
    <div id="Menu">
      <h1>Meal</h1>
      <div id="MenuRepas" style="text-align:left; padding:1em;">
        <h2 style="font-size:2em;" v-for="(item,index) in seeDetailPlat" :key="index">
          - {{" "}}{{item}}
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
import MarqueeText from "vue-marquee-text-component";
import "animate.css";
import swal from "sweetalert";
import { QrcodeStream } from "vue3-qrcode-reader";
import axios from "axios";
export default {
  data() {
    return {
      supplement:0,
      hiddenSpinner: false,
      userAmountStaffBreakFast: "",
      userAmountStaffLunch: "",
      userAmountStaffSnack: "",
      userAmountStaffDinner: "",
      ecriteau: false,
      showCancel: true,
      showMsgMenu: true,
      LogoEntreprise: false,
      spinner: false,
      jourSemaine: null,
      typeRepas: null,
      jour: null,
      type: null,
      type_repas_id: "",
      elem: [1, 2, 3, 4, 5],
      elemCaterer:[1,2,3,4,5,6,7,8,9,0],
      quantite: "",
      close: false,
      showQr: true,
      codeQrForCard: null,
      showVideoQr: false,
      showInputBarre: false,
      codeBarre: true,
      MycodeBarre: "",
      show: false,
      ticket: "cash.png",
      showTicket: false,
      validite_du_ticket: null,
      account: false,
      my_account_solde: null,
      jourDay: new Date().toLocaleDateString("fr"),
      heureDay: new Date().toLocaleTimeString("fr"),
      inputLecteurCode: "",
      verf_autofocus: true,
      camera: "auto",
      type_scanne: null,
      idOfperson: "",
      heure_day: 8,
      type_hour_pdj: null,
      sorry: null,
      jour_day_semaine: new Date().getDay(),
      prix_type_repas: null,
      tableau_repas: null,
      showWindowRepas: false,
      slideEvent: false,
      timer: null,
      spinnerForShowRepas: false,
      finalyQuantity: "",
      disabledInputLecteurCode: false,
      imagesEvents: [
        {
          id: 1,
          src: require("../src/assets/events/dadju.jpg"),
          title: "daju concert",
        },
        {
          id: 2,
          src: require("../src/assets/events/fes.jpg"),
          title: "fes",
        },
        {
          id: 3,
          src: require("../src/assets/events/masa.jpg"),
          title: "massa",
        },
      ],
      userInfo: "",
      carteRequest: 0,
      takeAway: 0, 
      caterer_value:0,
      total_price: 0,
      apiUrl: this.$store.state.API_URL,
      seeDetailPlat:[],
      quantiteCaterer:"",
    };
  },
  components: {
    QrcodeStream,
    MarqueeText,
  },
  methods: {
    close_Event() {
      this.slideEvent = false;
    },
    closeWindowMyMenu() {
      this.showWindowRepas = !this.showWindowRepas;
    },
    decomposerRepas(dataRepas){
      const newTableau = [];
      dataRepas.split('-').forEach(item=>{
       if(item != ''){
        newTableau.push(item)
       } 
       })
       return newTableau;
    },
    showMyMenu(repas) {
      this.showWindowRepas = !this.showWindowRepas;
      this.seeDetailPlat = this.decomposerRepas(repas)
      // let My_Repas = document.querySelector("#MenuRepas");
    
     
      // My_Repas.textContent = repas;
    },
    startFrontCamera() {
      this.camera = "off";
    },
    clickBtn1() {
      if (this.userInfo === "Staff") {
        let btn = document.getElementById("pt");
        this.type_repas_id = btn.value;
        this.prix_type_repas = this.userAmountStaffBreakFast;
      } else {
        let btn = document.getElementById("pt");
        this.type_repas_id = btn.value;
        this.prix_type_repas = btn.title;
        //console.log("PRIX", btn.title);
      }

      //console.log("TYPE_ID", this.type_repas_id);
      this.close = !this.close;
    },
    clickBtn2() {
      if (this.userInfo === "Staff") {
        let btn = document.getElementById("dj");
        this.type_repas_id = btn.value;
        this.prix_type_repas = this.userAmountStaffLunch;
      } else {
        let btn = document.getElementById("dj");
        this.type_repas_id = btn.value;
        this.prix_type_repas = btn.title;
        //console.log("TYPE_ID", this.type_repas_id);
        //console.log("PRIX", btn.title);
      }
      // this.$store.state.isVisibleSupAndTakeAway = true
      this.$store.state.isVisibleSup = true
      // this.$store.state.isVisibleTakeAway = true     
      this.close = !this.close;
    },
    clickBtn3() {
      if (this.userInfo === "Staff") {
        let btn = document.getElementById("gouter");
        this.type_repas_id = btn.value;
        this.prix_type_repas = this.userAmountStaffSnack;
        
      } else {
        let btn = document.getElementById("gouter");
        this.type_repas_id = btn.value;
        this.prix_type_repas = btn.title;
        //console.log("TYPE_ID", this.type_repas_id);
        //console.log("PRIX", btn.title);
      }
      this.$store.state.isVisibleTakeAway = false
      this.close = !this.close;
    },
    clickBtn4() {
      //console.log(this.userInfo);
      if (this.userInfo === "Staff") {
        let btn = document.getElementById("dinner");
        this.type_repas_id = btn.value;
        this.prix_type_repas = this.userAmountStaffDinner;
      } else {
        let btn = document.getElementById("dinner");
        this.type_repas_id = btn.value;
        this.prix_type_repas = btn.title;
        //console.log("TYPE_ID", this.type_repas_id);
        //console.log("PRIX", btn.title);
      }
      // this.$store.state.isVisibleSupAndTakeAway = true

      this.$store.state.isVisibleSup = true
      // this.$store.state.isVisibleTakeAway = true
      this.close = !this.close;
    },
    onDecode(decodedString) {
      //console.log("test", decodedString);
      this.MycodeBarre = decodedString;
      //console.log("CODE BARRE", this.MycodeBarre);
      this.verifCode();
    },
    action(n) {
      // this.quantite += n + "";
      this.finalyQuantity = n;

    },
    actionCaterer(n){
    this.quantiteCaterer += n + "";
    this.finalyQuantity = this.quantiteCaterer;
    }, 
    remove() {
     this.quantiteCaterer="";
      this.finalyQuantity = "";
    },
    verifLecteurCode() {
      this.spinnerForShowRepas = true;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.showCancel = false;
        this.hiddenSpinner = true;
        this.ecriteau = true;
        this.verifCode();
        this.spinnerForShowRepas = false;
      }, 300);
    },
    verifCode() {
      this.spinnerForShowRepas = true;
      //console.log("qr");
      this.carteRequest = 1;
      this.disabledInputLecteurCode = true;
      axios
        .post(this.apiUrl + "/verif-code", {
          code: this.inputLecteurCode,
          type: "carte",
        })
        .then((response) => {
          this.carteRequest = 0;
          if (response.data.status === true) {
            localStorage.setItem("@iduserConnect",JSON.stringify(response.data.data.id))
            this.$store.state.functionPersonne = response.data.data.function
            this.showVideoQr = false;
            this.show = true;
            this.showInputBarre = !this.showInputBarre;
            this.userInfo = response.data.data.function;
            this.userAmountStaffBreakFast = this.type[0].cout_staff;
            this.userAmountStaffLunch = this.type[1].cout_staff;
            this.userAmountStaffSnack = this.type[2].cout_staff;
            this.userAmountStaffDinner = this.type[3].cout_staff;
            this.idOfperson = response.data.data.matricule;
            // this.codeQrForCard = response.data.data.code_qr;
          }
          if (response.data.status === false) {
            swal({
              icon: "error",
              button: false,
              timer: 1000,
              title: response.data.message,
            });
            this.hiddenSpinner = !this.hiddenSpinner;
            this.showCancel = !this.showCancel;
            this.ecriteau = !this.ecriteau;
            this.showMsgMenu = !this.showMsgMenu;
            this.showInputBarre = !this.showInputBarre;
            this.codeBarre = !this.codeBarre;
            this.disabledInputLecteurCode = false;
            this.inputLecteurCode = "";
          }
          this.carteRequest = 0;
          this.spinnerForShowRepas = false;
        })
        .catch((error) => {
          this.carteRequest = 0;
          console.log(error);
          this.disabledInputLecteurCode = false;
        });
    },
    send() {
      // console.log({
      //   type_repas_id: this.type_repas_id,
      //   code: this.inputLecteurCode,
      //   qte: this.quantite,
      //   takeAway: this.takeAway,
      //   caterer:this.caterer_value
      // })
      axios
        .post(this.apiUrl + "/ticket", {
          type_repas_id: this.type_repas_id,
          code: this.inputLecteurCode,
          qte: this.finalyQuantity,
          takeaway: this.takeAway, 
          supplement:this.supplement,
          caterer:this.caterer_value
        })
        .then((response) => {
          if (response.data.status === true) {
            swal({
              icon: "success",
              toast: true,
              title: "Ticket printing in progress....",
              timer: 1500,
              button: false,
            });

            this.total_price = response.data.total_cost
            this.ticket = response.data.codeBar;
            //console.log(this.ticket);
            this.validite_du_ticket = new Date(
              response.data.validity
            ).toLocaleDateString("fr");

            this.showMyTicket();

            this.inputLecteurCode = "";
        
            axios.get(this.apiUrl + "/generateCodeWeb/"+ JSON.parse(localStorage.getItem('@iduserConnect')))
            .then((response)=>{
              console.log(response)
            })
            .catch(error=>console.log(error));
            //console.log("VALIDIDTE", this.validite_du_ticket);
          }
          if (response.data.status === false) {
            swal({
              icon: "error",
              button: false,
              timer: 3000,
              title: response.data.message,
            });
            this.disabledInputLecteurCode = false;
          }
          localStorage.removeItem('@iduserConnect')
        })
        .catch((error) => {
          console.log(error);
          this.disabledInputLecteurCode = false;
        });
      //console.log("Q", this.quantite);
      //console.log("TYPE", this.type_repas_id);
      this.carteRequest = 0;
    },
    fermer() {
      this.$store.state.isVisibleTakeAway = false
      this.$store.state.isVisibleSup = false
      this.close = !this.close;
      // this.quantite = "";
      this.remove()
      this.carteRequest = 0;
      this.supplement = 0
      this.takeAway = 0
      this.caterer_value = 0;
    },
    showQrMethod() {
      this.codeBarre = !this.codeBarre;
      this.showVideoQr = !this.showVideoQr;
      this.camera = "front";
    },
    showInputOfCodeBarre() {
      this.showInputBarre = !this.showInputBarre;
      this.showMsgMenu = !this.showMsgMenu;
      this.codeBarre = !this.codeBarre;
      setTimeout(() => {
        document.getElementById("input_lecteur").focus();
      }, 100);
    },
    annulerCommande() {
      this.hiddenSpinner = !this.hiddenSpinner;
      this.showCancel = !this.showCancel;
      this.showMsgMenu = !this.showMsgMenu;
      this.ecriteau = !this.ecriteau;
      this.show = !this.show;
      this.codeBarre = !this.codeBarre;
      this.MycodeBarre = "";
      this.camera = "off";
      this.inputLecteurCode = "";
      this.carteRequest = 0;
      this.disabledInputLecteurCode = false;
    },
    retourCommande() {
      this.codeBarre = !this.codeBarre;
      this.showInputBarre = !this.showInputBarre;
      this.showMsgMenu = !this.showMsgMenu;
      this.inputLecteurCode = "";
      this.carteRequest = 0;
      this.disabledInputLecteurCode = false;
    },
    showMyTicket() {
      this.showTicket = true;
      if (this.showTicket) {
        setTimeout(() => {
          this.printDiv();
        }, 3500);
      }
    },

    hideVideo() {
      this.showVideoQr = false;
      this.codeBarre = true;
      this.MycodeBarre = null;
      this.camera = "off";
    },
    show_account() {
      this.spinnerForShowRepas = true;
      axios
        .post(this.apiUrl + "/consulte-solde", {
          code: this.inputLecteurCode,
        })
        .then((response) => {
          //console.log("MONEY", response);
          this.account = !this.account;
          let moneyFormat = new Intl.NumberFormat("de-DE");
          this.my_account_solde = moneyFormat.format(response.data.data);
          this.spinnerForShowRepas = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fermer_account() {
      this.account = !this.account;
    },
    printDiv() {
      let divContents = document.getElementById("my_ticket");
      let imageContent = document.getElementById("imageContent");
      // let moneyFormat = new Intl.NumberFormat("de-DE");
      // let prix = moneyFormat.format(this.total_price);
      let a = window.open(
        "",
        "null",
        "height=200,width=200,toolbar=0, location=0, status=0, scrollbars=0, resizable=0"
      );
      a.moveBy(1200, 1200);
      a.document.write("<html><head>");
      a.document.write(
        "</head><body style='display:flex; flex-direction:column; width:300; align-items:center;'>"
      );
      a.document.write(`<div>
      <b style='display:block;'>------------------------------</b>
      <b style='margin-left:20px'>Ticket Of Meal</b>
      <b style='display:block;'>------------------------------</b>
      </div>`);
      
      a.document.write(`<div style='margin-bottom:10px;'>
        <p>ID : ${this.idOfperson}</p>`)
        this.supplement ? a.document.write(`<span>Supplement: yes</span> <br>`) : a.document.write(`<span>Supplement: no</span> <br>`) 
        this.takeAway ? a.document.write(`<span>take away: yes</span> <br>`) : a.document.write(`<span>take away: no</span> <br>`) 
      //   a.document.write(`
      //   <span>the price of meal is : </span>
      //   <span>${prix}XOF</span>
        
      // </div>`);
      a.document.write(`<div class="d-flex justify-content-center">
      ${divContents.innerHTML}
      </div>`);
      a.document.write(`<div>
            <b style='font-size:1em;'>Enjoy your meal !</b>
            </div>`);
      a.document.write(`<div>
              ${imageContent.innerHTML}
            </div>`);
      a.document.write("</body></html>");
      a.document.close();
      setTimeout(() => {
        a.print();
        a.close();
      }, 1000);
      this.showTicket = !this.showTicket;
      this.close = !this.close;
      this.show = !this.show;
      this.codeBarre = !this.codeBarre;
      this.showCancel = !this.showCancel;
      this.showMsgMenu = true;
      this.hiddenSpinner = false;
      this.disabledInputLecteurCode = false;
      this.MycodeBarre = null;
      // this.quantite = "";
      this.camera = "off";
    },
    changeVariable() {
      let count = 0;
      setInterval(() => {
        let verif = new Date().getDay();
        if (verif != this.jour_day_semaine) {
          this.jour_day_semaine = verif;
        }
        this.jour_day_semaine = count;
      }, 1000);
    },
  },
  created() {
    this.spinner = true;
    axios
      .get(this.$store.state.API_URL + "/menu-semaine")
      .then((reponse) => {
        // console.log("ddd", reponse.data);
        if (reponse.data.status === false) {
          this.sorry = reponse.data.message;
        }
        if (reponse.data.status === true) {
          this.jourSemaine = reponse.data.data.jours;
          this.jourSemaine.forEach((item) => {
            if (item.id === 7) {
              item.id = 0;
            }
          });

          if (this.jourSemaine[0]) {
            this.type = this.jourSemaine[0].repas.type_repas;
            this.tableau_repas =
              this.jourSemaine[1].repas.type_repas[1].pivot.libelle.split("-");
            // this.type_hour_pdj = (this.type[0].hour_start).split('')[1]
            //console.log("REPAS_HOUR", this.tableau_repas);
          }
        }

        this.spinner = false;
        // console.log("JOURS", this.jourSemaine);
        // console.log("TYPE", this.type);
        // console.log(this.userAmountStaffBreakFast);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  mounted() {
    //console.log("DAY", this.jour_day_semaine);
    this.carteRequest = 0;
    setInterval(() => {
      this.slideEvent = !this.slideEvent;
    }, 50000);

    setInterval(() => {
      let verif = new Date().getDay();
      if (verif != this.jour_day_semaine) {
        this.jour_day_semaine = verif;
      }
    }, 1000);
  },
  computed: {
    get_My_hour() {
      return this.My_heure + ":" + this.My_minute + ":" + this.My_Seconde;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rancho&display=swap');

input[type="radio"] {
  appearance: none; /* Cache l'apparence par défaut */
  -webkit-appearance: none; /* Pour WebKit (Safari, Chrome) */
  -moz-appearance: none; /* Pour Firefox */
  width: 20px;
  height: 20px;
  border: 2px solid #000; /* Bordure noire */
  border-radius: 50%; /* Forme arrondie */
  outline: none;
  cursor: pointer;
  margin-right: 10px;
}

/* Style lorsque l'input radio est sélectionné */
input[type="radio"]:checked {
  background-color: black; /* Couleur noire lorsque sélectionné */
  border-color: black;
}

/* Ajout d'une animation ou d'un effet pour le clic (facultatif) */
input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: white; /* Petit cercle blanc à l'intérieur */
  border-radius: 50%;
  margin: 3px auto;
}
legend{
  font-weight:bold;
}
.btn-info:active,.btn-info:hover{
  background:#FF7A00;
  border: 1px solid transparent;
}
.radios {
  display: flex;
  justify-content: space-between;

  width: 150px;
  font-size: 35px;
}

.away {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;

  margin-bottom: 10px;
}

marquee>h1 {
  color: rgb(255, 255, 255);
  font-weight: 900 !important;
  font-size: 4em;
}

.cont-marquee {
  width: 100% !important;
  font-size: 3em;
}

.image_stop {
  width: 50px;
  height: 50px;
}

.account-solde {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.534);
  position: fixed;
  display: flex;
  place-items: center;
  justify-content: center;
  font-family: "Rancho";
}

.account-tableau-solde {
  width: 60vh;
}

.badge {
  margin-left: 50%;
  transform: translateX(-50%);
  background: #061d40;
  border-radius: 2vh;
  padding-bottom: 7vh;
}

.account-solde h1 {
  text-align: center;
  margin-top: 15vh;
  font-size: 6vh;
  font-weight: 900;
}

.close-account {
  right: 0;
  font-family: "Rancho";
  font-size: 5vh;
  font-weight: 900;
}

.head-table {
  margin-top: 2.1em;
}

.annuler_video {
  font-size: 5vh;
  top: 0;
  right: -25vh;
  font-weight: bold;
  color: white;
}
.btn-primary:hover{
  background: #FF7A00 !important;
  border:2px solid white; 
}
.qr {
  height: 8vh;
  width: 30vh;
  font-size: 3.5vh;
  background: #FF7A00;
  border: 1px solid #FF7A00;
  border-radius: 10px;
}

.quantite {
  width: 50vh;
  height: 5vh;
  background: transparent;
  font-size: 4vh;
  color: rgb(0, 0, 0);
  padding: 0 0.5vh;
  border: 3px solid black;
  text-align: right;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 50vh;
  text-align: center;
  grid-gap: 0.5vh;
}

.grid span {
  padding: 2vh;
  font-size: 3vh;
  background: transparent;
  color: black;
  cursor: pointer;
  border: 3px solid black;
  border-radius: 10px;
  font-weight: bold;
}

.my_form {
  background: white;
  width: 80vh;
  height: 80vh;
  padding-bottom: 1vh;
  display: flex;
  place-items: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y:auto;

}
.add-padding-20{
  padding:20em 0 2em 0;
}
.add-padding-5{
  padding:5em 0 2em 0;
}

/* Style pour les navigateurs WebKit (Chrome, Safari, Edge, etc.) */
.my_form::-webkit-scrollbar {
  width: 12px; /* Largeur de la barre de défilement */
}

.my_form::-webkit-scrollbar-track {
  background: white; /* Couleur de fond */
}

.my_form::-webkit-scrollbar-thumb {
  background-color: orange; /* Couleur de la barre de défilement */
  border-radius: 10px; /* Arrondir les coins de la barre de défilement */
  border: 3px solid white; /* Espace autour de la barre (optionnel) */
}

/* Optionnel : style pour les barres de défilement dans Firefox */
.my_form {
  scrollbar-width: thin;
  scrollbar-color: orange white;
}

.align-self-end {
  margin-right: 2vh;
}

.pop {
  display: flex;
  place-items: center;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.63);
  width: 100%;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  font-family: "Rancho";
}

.pop p {
  font-size: 3vh;
  font-weight: 900;
  padding: 0;
  margin: 0;
}

.table-responsive {
  overflow-x: none;
}

.btn-success,
.btn-info,
.btn-danger,
.pop .btn,
.btn-dark {
  height: 8vh;
  width: 15vw;
  font-size: 3.5vh;
}

.fermer_quantity {
  margin-top: -20px;
}

.qrcode {
  z-index: 99999;
  transform: translateX(-50%);
  margin-left: 50%;
  bottom: 1vh;
  width: 45vh;
  height: 30vh;
  border: 1vh solid white;
}

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 1vh 0.5vh 1vh;
}

.container-fluid {
  padding: 0;
}

#nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: content-box linear-gradient(rgba(81, 81, 81, 0.679), rgba(203, 202, 202, 0.452)),
    url("../src/assets/image-uigb.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: "Rancho";
  font-weight: 900;
  color: white;
  text-transform: capitalize;
}

.type_of_repas {
  padding: 3vh 0;
  margin: 0 auto;
  background: rgb(253, 253, 253);
  border: none;
  font-size: 2em;
  color: #0b4981;
  border: 1px solid rgb(255, 255, 255);
  font-size: 4vh;
  margin: 1vh;
  overflow: hidden;
}

td {
  height: 14vh;
  text-align: center;
  border: 2px solid rgb(224, 224, 224);
  background: rgba(124, 124, 124, 0.699);
  font-size: 3.5vh;
  margin: 1vh 0.2vh;
  border-radius: 5px;
  overflow: hidden;
  line-height: 1.3em;
}

/*td span b {
  text-overflow: ellipsis;
}*/

td::-webkit-scrollbar {
  width: 5px;
}

td::-webkit-scrollbar-track {
  background: #d3d2d2bd;
}

td::-webkit-scrollbar-thumb {
  background: #012b61;
}

tbody tr {
  width: 33.33%;
}

.semaine {
  background: rgb(245, 245, 245);
  color: #0b4981;
  font-size: 3vh;
}

.jour_semaine {
  background: #FF7A00;;
  font-weight: 900;
  animation: move 1s ease-in-out infinite alternate;
  color: white;
}

@keyframes move {
  from {
    transform: scale(1.2, 1.2);
  }

  to {
    transform: scale(1, 1);
  }
}

th:not(:nth-child(1)) {
  margin-right: 1vh;
  margin-bottom: 1vh;
  border-radius: 10px;
}

.titre {
  font-size: 4vh;
  font-weight: 900;
}

.affiche_ticket {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  place-items: center;
  z-index: -1;
}

.ticket-for-print {
  justify-content: center;
  align-items: center;
  place-items: center;
}

.ticket-for-print div {
  width: 50vh;
}

.validite_du_ticket {
  font-weight: bold;
  z-index: 90;
  margin-top: -10vh;
  font-size: 2vh;
}

.lecteur_code {
  width: 40vh;
  border-radius: 10px;
  background: #FF7A00;
  border: 1px solid #FF7A00;
}

.color,.selectedQuantity {
  background: #FF7A00 !important;
}
.color_disabled{
  background:rgb(53, 53, 53)
}

.solde {
  background: rgb(243, 242, 243);
  font-weight: bold;
  width: 30vh;
  color: black;
}

.img_cash {
  width: 6vh;
  right: 0;
  top: 0;
  margin-top: 1vh;
  margin-right: 0.5vh;
  background: rgba(255, 255, 255, 0.979);
}

.sortir {
  font-weight: bold;
  width: 30vh;
}

.input {
  width: 50vh;
  height: 5vh;
  font-size: 5vh;
}

.img_qrcode {
  width: 6vh;
  right: 0;
  top: 0;
  margin-top: 1vh;
  margin-right: 0.5vh;
}

.indep {
  background: transparent;
  color: transparent;
}

.btn-retour {
  color: white;
  font-weight: bold;
  background: crimson;
  border: 1px solid crimson;
  width: 25vh;
}

.bouton-type {
  background: white;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.514);
  border: 1px solid white;
  color: black;
  font-weight: bold;
  width: 16vh;
  height: 16vh;
  border-radius: 30% 70% 38% 62% / 30% 30% 70% 70%;
  font-size: 3vh;
}

.bouton-type:hover {
  color: black;
}

.enter {
  background: #FF7A00 !important;
  color: white;
  border: 1px solid #FF7A00 !important;
}

.affichage-type-menu {
  bottom: -4vh;
}

.bouton-one {
  bottom: 15vh;
  left: 45%;
}

.bouton-one:focus,
.bouton-two:focus,
.bouton-three:focus,
.bouton-four:focus {
  transform: scale(2, 2);
  z-index: 2;
}

.bouton-two {
  bottom: 5vh;
  left: 33%;
}

.bouton-three {
  bottom: 5vh;
  left: 58%;
}

.bouton-four {
  top: -13vh;
  left: 45%;
}

.food-dinner {
  top: 0;
  z-index: -1;
  margin-left: -50%;
  transform: translateX(50%);
}

.name-food-dinner {
  margin-left: -45%;
  transform: translateX(50%);
  bottom: 3vh;
}

.food-meal-one {
  top: -12px;
  z-index: -1;
  margin-left: -55%;
}

.food-meal-two {
  top: -9px;
  z-index: -1;
  margin-left: -50%;
}

.food-gouter {
  top: -9px;
  z-index: -1;
  margin-left: -40%;
}

.food-spa {
  top: -20px;
  z-index: -1;
  margin-left: -50%;
}

.name-dj {
  margin-right: 50%;
  transform: translateX(-50%);
  bottom: 2vh;
}

.name-pt::first-letter,
.name-gouter::first-letter,
.name-dj::first-letter {
  font-size: 3vh;
}

.name-pt {
  margin-right: 50%;
  transform: translateX(-50%);
  bottom: 1.5vh;
  font-weight: 900;
  font-size: 2.6vh;
}

.name-gouter {
  margin-right: 50%;
  transform: translateX(-50%);
  bottom: 2vh;
}

.money-solde {
  right: -10px;
  top: 0;
}

.my_money_solde {
  top: 20%;
  position: absolute;
  z-index: 3;
  margin-left: -50%;
  transform: translateX(50%);
}

button:active {
  transform: scale(0.8, 0.8);
}

.Date,
.Hour {
  margin: 0;
  padding: 0;
}

@media print {
  #my_ticket {
    background: #000;
  }
}

.not-menu {
  display: flex;
  justify-content: center;
  place-items: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.404);
}

.not-menu h1 {
  font-size: 10vh;
}

.lc-rifd {
  margin-left: -1em;
  font-weight: 900;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px; /* La largeur de l'élément, à ajuster selon ton besoin */
}

.window_repas {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background: transparent;
  place-items: center;
  justify-content: center;
  z-index: 999;
  font-family: "Rancho";
}

#Menu {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.711);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.2px);
  -webkit-backdrop-filter: blur(5.2px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: auto;
  height: auto;
  padding: 0 0 2em 0;
  text-align: center;
  color: rgb(7, 7, 7);
  font-size: 2em;
  text-transform: uppercase;
}

#Menu h1 {
  background:  #FF7A00;
  border-radius: 15px;
  width: 50%;
  margin: 0.3em auto;
  color: #fff;
  text-transform: uppercase;
}

.event {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 999;
}

.close_events {
  right: 1em;
  top: 0.5em;
  z-index: 9999;
}

.img-event {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Myspinner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgb(6, 0, 95);
  display: flex;
  place-items: center;
  justify-content: center;
  flex-direction: column;
}

.Myspinner_for_show_repas {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.21);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  place-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 838px) {
  span {
    font-size: 0.7em;
  }

  td {
    line-height: 1.5em;
  }

  .w-25 {
    width: 20% !important;
  }

  #MenuRepas {
    font-size: 2em;
  }

  .bouton-two {
    left: 30%;
  }

  .bouton-one {
    left: 42%;
  }

  .bouton-three {
    left: 55%;
  }

  .solde {
    width: 30vh;
  }
}

@media screen and (max-width: 601px) {
  span {
    font-size: 0.7em;
  }

  th[data-v-7ba5bd90]:not(:nth-child(1)) {
    margin-right: -0.1em;
    border-radius: 2px;
  }

  .w-25 {
    width: 10% !important;
  }

  #MenuRepas {
    font-size: 1.5em;
  }

  .solde {
    width: 20vh;
    margin-right: -1em;
  }

  .bouton-two {
    left: 27%;
  }

  .bouton-one {
    left: 39%;
  }

  .bouton-three {
    left: 52%;
  }
}

@media screen and (max-width: 599px) {
  span {
    font-size: 0.6em;
  }

  #MenuRepas {
    font-size: 1em;
  }
}

@media screen and (max-width: 525px) {
  span {
    font-size: 0.5em;
  }

  #MenuRepas {
    font-size: 1em;
  }
}

@media screen and (max-width: 452px) {
  span {
    font-size: 0.489em;
  }

  #MenuRepas {
    font-size: 0.8em;
  }
}

.changequantity {
  background: rgba(229, 229, 229, 0.947) !important;
}
</style>
