import { createStore } from 'vuex'

export default createStore({
  state: {
    jour_day_semaine : new Date().getDay(),
    API_URL: 'https://backend.iugb-ci.com/api',
    functionPersonne:null,
    isVisibleSup:false,
    isVisibleTakeAway:false,
    // API_URL: 'http://192.168.1.6:8000/api'
  },
  mutations: {
  
  },
  actions: {

  },
  modules: {

  }
})
